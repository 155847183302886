import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { createPersistentBackendAdapter } from "../backend/adapter";
import { getRefreshToken } from "../backend/rest";
import { BackendApi, createBackendStateHandler } from "../backend/state-handler";
import { BackendState, ServiceProps } from "../types";
import { getRandomIdentifier } from "../util/random";
import { alertsAtom } from "./alerts";
import { itemsAtom } from "./items";
import { areasAtom, blueprintAtom, floorsAtom } from "./map";
import { connectionStateAtom, modulesAtom, progressivePhasesAtom, progressiveWorkshiftsAtom, progressiveTasksAtom, currentModuleAtom, currentPageAtom, currentProgressivePhaseAtom, progressiveLimits } from "./misc";
import { tasksAtom } from "./tasks";
import { useAsyncError } from "../hooks";
import env from "react-dotenv";

export type Credentials = {
  username: string;
  password: string;
};

export const useBackendIntegration = ({ services }: ServiceProps, onLogOut: () => void): BackendApi | null => {

  const [api, setApi] = useState<BackendApi | null>();
  const setConnectionState = useSetRecoilState(connectionStateAtom);
  const setAlerts = useSetRecoilState(alertsAtom);
  const setAreas = useSetRecoilState(areasAtom);
  const setFloors = useSetRecoilState(floorsAtom);
  const setBlueprint = useSetRecoilState(blueprintAtom);
  const setTasks = useSetRecoilState(tasksAtom);
  const setItems = useSetRecoilState(itemsAtom);
  const setModules = useSetRecoilState(modulesAtom);
  const setPhases = useSetRecoilState(progressivePhasesAtom);
  const setShifts = useSetRecoilState(progressiveWorkshiftsAtom);
  const setProgressiveTasks = useSetRecoilState(progressiveTasksAtom);
  const setCurrentModule = useSetRecoilState(currentModuleAtom);
  const setCurrentPage = useSetRecoilState(currentPageAtom);
  const setCurrentPhase = useSetRecoilState(currentProgressivePhaseAtom);
  const setLimits = useSetRecoilState(progressiveLimits);
  const throwError = useAsyncError();
  const LOCALSTORAGE_KEY = "_dstm";

  useEffect(() => {
    (async () => {
      try {
        if (api) return;

        const updateStoredAuth = ({ accessToken, refreshToken, expiresTs, gate }: { accessToken: string; refreshToken: string; expiresTs: number; gate: number | null }) => {
          window.localStorage.setItem(
            LOCALSTORAGE_KEY,
            JSON.stringify({
              at: accessToken,
              rt: refreshToken,
              exp: expiresTs,
              gat: gate
            }),
          );
        };

        let previousAuth = null;

        let accessToken: string | null = null;
        let refreshToken: string | null = null;
        let expiresTs: number | null = null;
        let gate: number | null = null;

        try {
          const { at, rt, exp, gat } = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) || "");
          if (at && rt && exp) {
            previousAuth = true;
            accessToken = at;
            refreshToken = rt;
            expiresTs = parseInt(exp);
            gate = parseInt(gat);
          }
        } catch (e) {
          // Ignore.
          console.log("ERROR");
        }
        let href = window.location.href;
        let search = window.location.href.split('#');
        href = search[0];
        if (!previousAuth) {

          if (search.length > 1) {
            let params = new URLSearchParams('?' + search[1]);
            accessToken = params.get('at');
            refreshToken = params.get('rt');
            let ex = params.get('exp');
            if (ex) expiresTs = parseInt(ex);
            let g = params.get('gate');
            if (g) gate = parseInt(g);
            window.location.href = href;
          }


          if (!accessToken || !refreshToken || !expiresTs) {
            onLogOut();
            return;
          }

          updateStoredAuth({
            accessToken: accessToken,
            refreshToken: refreshToken,
            expiresTs: expiresTs,
            gate: gate
          });

          return;
        }

        if (!accessToken || !refreshToken || !expiresTs) {
          onLogOut();
          return;
        }

        if (search.length > 1) {
          window.location.href = href;
        }

        let refreshUrl = env.REACT_APP_REFRESH_URL as string;

        setConnectionState(BackendState.Connecting);

        const fnRefreshToken = async ({ refreshToken }: { refreshToken: string }) => {
          const r = await getRefreshToken({
            url: refreshUrl,
          })({ refreshToken });
          if (!(r instanceof Error)) {
            try {
              const newAuth = {
                accessToken: r.accessToken,
                refreshToken: r.refreshToken,
                expiresTs: Math.floor(Date.now() + r.expiresIn * 1000),
                gate: gate
              };
              updateStoredAuth(newAuth);
              services.logger.log("Stored updated token");
            } catch { }
          }
          return r;
        };

        const r2 = await createPersistentBackendAdapter({ fnRandomIdentifier: getRandomIdentifier, fnRefreshToken, logger: services.logger, setConnectionState, onLogOut })({ accessToken, refreshToken, expiresTs });

        if (r2 != null) {
          const r3 = createBackendStateHandler({ api: r2, logger: services.logger })({ setAlerts, setAreas, setFloors, setBlueprint, setItems, setTasks, setModules, setPhases, setShifts, setProgressiveTasks, setCurrentModule, setCurrentPage, gate, setCurrentPhase, setLimits, throwError });
          r2.addAfterConnectedHandler(() => {
            r3.init();
          });

          setApi(r3);

          // Connect socket.
          r2.init();
        }

      } catch (e) {
        services.logger.error("Exception while authenticating", e);
        setConnectionState(BackendState.AuthenticationError);
        throwError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return api || null;
};
