import React from "react";
import styles from "../styles/Header.module.css";
import FixedSelect from "./Select";
import { Translations } from "../hooks";
import clsx from "clsx";
import { ModuleType } from "@noccela/dna-iot-shared";
import { useRecoilValue } from "recoil";
import { currentModuleAtom, modulesAtom } from "../state/misc";

type Opt = {
	value: ModuleType;
	label: string;
}
type Props = {
	logo?: React.ReactElement;
	title?: React.ReactNode;
	nav?: React.ReactElement;
	translations: Translations;
	onModuleChanged: (s: ModuleType) => void;
};

export const Header = ({ logo, title, nav, onModuleChanged, translations }: Props) => {

	const modules = useRecoilValue(modulesAtom);
	const currentModule = useRecoilValue(currentModuleAtom);

	const modeOpts = modules.map(a => {
		return {
			value: a,
			label: a.split(/(?=[A-Z])/).join(' ')
		}
	});

	let currentModeOpt = modeOpts.find(a => a.value == currentModule) ?? null;

	return (
		<header className={clsx("flex flex-nowrap flex-row gap-x-4 card", styles.header)}>
			{logo && <div className="w-auto">{logo}</div>}
			<div className="flex w-autoflex flex-row items-center">
				<div className="w-auto font-bold" style={{ paddingRight: "1rem", textAlign: "center", fontSize: 15 }}>Powered by<br />Noccela</div>
				{title && <FixedSelect
					t={translations}
					height={36}
					isDisabled={false}
					options={modeOpts}
					value={currentModeOpt}
					wrapperClass={"w-auto"}
					onChange={(e: any) => {
						if (e) {
							onModuleChanged(e?.value);
						}
					}}
				/>}
			</div>
			{nav && <div className="mr-5 w-auto">{nav}</div>}
		</header>
	);
};

export default Header;
